<script setup lang="ts">
import { isEmpty } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { NearestCountries } from 'base-nuxt-layer/types/general';
import {
  useImage,
  useIntersectionObserver,
  ref,
  useDevice,
  useGeneralService,
  useNavigation,
  useRuntimeConfig,
} from '#imports';

const img = useImage();
const runtime = useRuntimeConfig();
const { country: configCountry } = storeToRefs(useConfigStore());
const { locale } = useI18n();
const { navigationPath } = useNavigation();

const device = useDevice();

const {
  data: countries,
  pending,
  execute: fetchHotelPopularDestination,
} = await useAsyncData(
  () =>
    useGeneralService().getNearestCountries({
      country: configCountry.value!,
      lang: locale.value,
    }),
  {
    immediate: device.isCrawler,
    transform: (data) => (data.result as NearestCountries).countries,
  },
);

const hotelRecommendationRef = ref<HTMLElement | null>(null);
const initialState = ref(!device.isCrawler);
const currentIndex = ref(0);
const slideRefs = ref<(HTMLElement | null)[]>([]);
const scrollContainer = ref<HTMLElement | null>(null);

const disabledNextButton = computed(
  () => isEmpty(countries.value) || currentIndex.value === countries.value!.length - 1,
);

const disabledPrevButton = computed(() => isEmpty(countries.value) || currentIndex.value === 0);

function nextSlide() {
  if (countries.value) {
    const slidesLength = countries.value.length;
    let nextIndex;

    if (currentIndex.value + 1 === slidesLength) nextIndex = slidesLength - 1;
    else if (currentIndex.value + 1 > slidesLength) nextIndex = 0;
    else nextIndex = (currentIndex.value + (currentIndex.value === 0 ? 2 : 1)) % slidesLength;

    scrollToSlide(nextIndex);
  }
}

function previousSlide() {
  if (countries.value) {
    const slidesLength = countries.value.length;
    let prevIndex;

    if (currentIndex.value - 1 < 0) prevIndex = 0;
    else
      prevIndex =
        (currentIndex.value - (currentIndex.value === slidesLength - 1 ? 2 : 1) + slidesLength) % slidesLength;

    scrollToSlide(prevIndex);
  }
}

function scrollToSlide(index: number) {
  const slide = slideRefs.value[index];

  if (slide) {
    const targetElement = slide instanceof HTMLElement ? slide : (slide as any)?.$el;

    if (targetElement && typeof targetElement.scrollIntoView === 'function') {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      currentIndex.value = index;
    }
  }
}

function setSlideRef(index: number, el: HTMLElement | null) {
  (slideRefs.value as (HTMLElement | null)[])[index] = el;
}

function getHotelByCountryPath(name: string) {
  return navigationPath({ path: '/hotel/country/' + normalizeSlug(`${name.replace(' ', '-')}`, true) });
}

function getCountryImage(country: string) {
  const countryName = country.split(' ').join('-').toLowerCase();

  if (!countryName) {
    return '';
  }

  return `${runtime.public.cdnAssetUrl}/countries/${countryName}.png`;
}

const { stop } = useIntersectionObserver(
  hotelRecommendationRef,
  // @ts-expect-error
  ([{ isIntersecting }]) => {
    if (isIntersecting && !device.isCrawler) {
      fetchHotelPopularDestination();

      initialState.value = false;

      stop();
    }
  },
);
</script>

<template>
  <div
    ref="hotelRecommendationRef"
    class="w-full flex flex-col gap-y-15"
  >
    <div class="flex justify-between items-center px-15">
      <h2 class="text-gray-darkest font-bold">{{ $t('hotelstaticpage.hotelcountryselection') }}</h2>

      <div class="flex justify-between w-[60px]">
        <ApzButton
          class="!p-0 !w-25 !h-25 flex justify-center items-center !rounded-full shadow-md"
          :disabled="disabledPrevButton"
          @click="previousSlide()"
        >
          <ApzIcon
            class="text-xsmall"
            :icon="['fas', 'chevron-left']"
          />
        </ApzButton>

        <ApzButton
          class="!p-0 !w-25 !h-25 flex justify-center items-center rounded-full shadow-md"
          :disabled="disabledNextButton"
          @click="nextSlide()"
        >
          <ApzIcon
            class="text-xsmall"
            :icon="['fas', 'chevron-right']"
          />
        </ApzButton>
      </div>
    </div>

    <div
      v-if="pending || initialState"
      class="w-full overflow-hidden"
    >
      <HotelRecommendationPlaceholderMobile />
    </div>

    <div
      v-else-if="!isEmpty(countries)"
      ref="scrollContainer"
      class="overflow-x-scroll snap-x snap-mandatory scrollbar-hide w-full px-15"
    >
      <div class="flex gap-x-10 pr-15">
        <NuxtLink
          v-for="(country, index) in countries"
          :ref="(el) => setSlideRef(index, (el as HTMLElement))"
          :key="index"
          :to="getHotelByCountryPath(country.enName)"
          class="relative flex-none w-[calc(50%-10px)] snap-start scroll-mx-20"
          external
          no-rel
        >
          <NuxtImg
            :src="getCountryImage(country.enName)"
            :alt="country.name || `Hotel Images_${index}`"
            :loading="index > 2 ? 'lazy' : 'eager'"
            format="webp"
            width="178"
            height="96"
            fit="cover"
            class="aspect-[178/96] w-full rounded object-cover"
            sizes="sm:50vw"
            quality="50"
            :placeholder="
              img('/img/placeholder.png', {
                sizes: 'lg:534px',
                modifiers: {
                  format: 'webp',
                  quality: 50,
                  height: 96,
                  width: 178,
                },
              })
            "
          />

          <div class="absolute inset-0 bg-gradient-to-t from-[#000000cc] to-[#69696900] rounded">
            <div class="absolute left-15 bottom-15 flex flex-col gap-y-5 text-white rounded">
              <h3 class="font-bold text-small">
                {{ country.name }}
              </h3>

              <p class="line-clamp-1 text-xsmall text-white">
                {{ $t('hotel.properties', { value: `${country.count || 0}+` }) }}
              </p>
            </div>
          </div>
        </NuxtLink>

        <div class="flex-none w-5" />
      </div>
    </div>
  </div>
</template>
